/* Bulletins block
-------------------------------------------------- */
@keyframes fade-in {
     0% {opacity: 0;}
     75% {opacity: 0;}
     100% {opacity: 1;}
}

.masonry {
    grid-gap: 1em;
    grid-auto-rows: 0;
    display: none;
}

.masonry.fade-in {
   animation-duration: 1s;
   animation-name: fade-in;
}

.masonry.edge.fade-in {
    display: grid;
}

.masonry.grid.fade-in {
    display: grid;
    display: -ms-grid;
}

.masonry-content {
    overflow: hidden;
    color: #2f3640;
    background-color: #eee;
    transition: opacity .25s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2);    
}

.masonry.grid-cols-2 {
    -ms-grid-columns: 1fr 1fr;
    float: left;
    width: 100%;
}

.masonry.grid-cols-3 {
    -ms-grid-columns: 1fr 1fr 1fr;
    float: left;
    width: 100%;
}

.masonry.grid-cols-4 {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    float: left;
    width: 100%;
}

.masonry-brick:hover {
    opacity: .75;
}

.masonry-brick {
    position: relative;
    margin-bottom: 10px;
}

.masonry-brick .headline-only,
.masonry-brick .title.prominent-title {
    background-color: #333;
    color: #fff;
    min-height: 100px;
}

.masonry-brick .title.prominent-title {
    min-height: auto;
    padding: 5px;
}

.masonry-brick .headline-only h5,
.masonry-brick .title.prominent-title h5 {
    margin: 0;
    padding: 10px;
}

.masonry-brick .headline-only h5 a,
.masonry-brick .title.prominent-title h5 a {
    color: #fff;
}

.masonry-brick .overlay {
    display: inline-block;
    background-color: rgba(255, 255, 255, .7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    color: #333;
    transition: all 300ms ease-out;
}

.masonry-brick .overlay:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}

.masonry-brick .overlay .tnt-svg {
    color: rgba(0, 0, 0, .6);
    font-size: 50px;
    margin: auto;
    text-align: center;
    vertical-align: middle;
}

.masonry-brick .overlay .title {
    font-size: 11px;
    line-height: 1.2em;
    padding: 5px;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.masonry-brick:hover .overlay {
    opacity: 1;
}

.bulletins-modal .modal-header {
    padding: 15px 15px 10px;
    background-color: #eee;
    color: #666;
    border: 0;
}

.bulletins-modal .modal-header .modal-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bulletins-modal .modal-body {
    padding: 0;
    background-color: #fff;
}

.bulletins-modal .modal-body iframe {
    display: block;
    width: 100%;
    height: 490px;
}

.height-help .overlay,
.height-help .masonry-content {
    height: 100%;
}

.height-help .masonry-content {
    background-color:#333
}

@media screen and (min-width: 375px) {
    .bulletins-modal .modal-body iframe {
        height: 595px;
    }
}